import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { take, switchMap, tap, of } from 'rxjs';
import { IPeerGroupCompetitors } from '../../../../../../core/models/results.model';
import { changeActiveSideTab, loadSidenavData } from '../../../../../../state/action';
import { AppState } from '../../../../../../state/app.state';
import { selectSidenavDataByTabIdAndKey } from '../../../../../../state/selectors';
import { GeneralInnovationActivityResultsService } from '../../../../services/general-innovation-activity-results.service';
import { CommonModule } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';
import { RouterLink, RouterOutlet } from '@angular/router';
import { NullableNumberPipe } from '../../../../../../shared/pipes/nullable-number.pipe';

@Component({
  selector: 'app-company-overview',
  standalone: true,
  imports: [CommonModule, NgxPaginationModule, NullableNumberPipe],
  templateUrl: './company-overview.component.html',
  styleUrl: './company-overview.component.css'
})
export class CompanyOverviewComponent implements OnInit, OnChanges {
  @Input() tabId!: string;
  @Input() query: string | null = null;
  @Input() gsubind: string[] | null = null;
  @Input() entityids: string[] | null = null;
  @Input() tab_name: string | null = null;

  constructor(private store: Store<AppState>, private apiService: GeneralInnovationActivityResultsService
  ) {

  }

  ngOnInit(): void {
  }
  showResults: boolean = false;
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['tabId']) {
      this.showResults = false;
      this.competitors_list = [];
      this.competitors_list_loading_failed = false;

      if (this.gsubind && this.gsubind[0] != null) {
        this.getCompetitors();
        this.showResults = true;
      } else {
        this.showResults = false;
      }

    }
  }

  // dummy_data:IPeerGroupCompetitors={
  //   relative_growth_3y: 0,
  //   absolute_growth_3y: 0,
  //   portfolio_size_20y: 0,
  //   quality_average: 0,
  //   ti_kw: '',
  //   entityid: '',
  //   companyid: 0,
  //   companytype: '',
  //   country: '',
  //   region: '',
  //   webpage: '',
  //   address: '',
  //   isin: '',
  //   tickersymbol: '',
  //   year_first_active: 0,
  //   year_last_active: 0,
  //   n_inventions: 0,
  //   num_parents: 0,
  //   subindustryname: '',
  //   rank: 0,
  //   green_invention_count_20y: 0,
  //   green_invention_20y_ratio: 0,
  //   invention_count_20y_pct: 0,
  //   green_invention_20y_ratio_pct: 0,
  //   green_invention_20y_ratio_bracket: 0,
  //   invention_count_3y: 0,
  //   quality_average_3y: 0,
  //   invention_count_3y_pct: 0,
  //   quality_average_3y_pct: 0,
  //   invention_count_3y_bracket: 5,
  //   quality_average_3y_bracket: 3,
  //   overall_bracket: 5,
  //   quality_average_3y_bracket_description: "The company is significantly behind in competition in terms of the strength of its innovative processes with a high likelihood of underperformance in the near future.",
  //   invention_count_3y_bracket_description:"The company is significantly behind in competition in terms of the strength of its innovative processes with a high likelihood of underperformance in the near future.",
  //   combined_bracket_description: "The company is significantly behind in competition in terms of the strength of its innovative processes with a high likelihood of underperformance in the near future.",
  //   entity_name:  'Apple Inc',
  //   invention_count_20y: 300000,
  //   invention_count_20y_bracket: 1,
  //   invention_count_20y_bracket_description: "The company is significantly behind in competition in terms of the strength of its innovative processes with a high likelihood of underperformance in the near future."
  // }

  onReadMoreClick(event: MouseEvent, option: string): void {
    event.preventDefault();
    this.store.dispatch(changeActiveSideTab({ sideTab: option }));
  }

  avg_portfolio_size_gics: number = 0;
  avg_recent_growth: number = 0;
  avg_innovation_quality: number = 0;

  competitors_list_loading_failed: boolean = false;
  competitors_list: IPeerGroupCompetitors[] = [];
  company_data: IPeerGroupCompetitors | undefined = undefined;
  paginator_1_page: number = 1;
  getCompetitors() {
    this.showResults = true;
    this.store.select(selectSidenavDataByTabIdAndKey(this.tabId, 'company_detailed_technology_benchmark')).pipe(
      take(1),
      switchMap((data) => {
        if (!data) {
          return this.apiService.getCompetitiveBenchmarkingPeerGroup(this.entityids, this.gsubind).pipe(
            tap((res) => {


              if (res.payload != null) {
                this.store.dispatch(loadSidenavData({
                  tabId: this.tabId,
                  sidenavKey: 'company_detailed_technology_benchmark',
                  data: res.payload
                }));

                this.competitors_list = res.payload;
                if (this.competitors_list.length > 0) {
                  this.avg_portfolio_size_gics = this.competitors_list.reduce((acc, company) => acc + company.invention_count_120m, 0) / this.competitors_list.length;

                  const growthValidCompanies = this.competitors_list.filter(company=>(company.invention_count_12m_prior!=0 && company.invention_count_12m!=0) )
                  this.avg_recent_growth = growthValidCompanies.reduce((acc, company) => acc + company.relative_growth_12m, 0) / growthValidCompanies.length;

                  const qualityValidCompanies = this.competitors_list.filter(company => company.quality_average_12m !== 0);
                  this.avg_innovation_quality = qualityValidCompanies.length > 0
                    ? qualityValidCompanies.reduce((acc, company) => acc + company.quality_average_12m, 0) / qualityValidCompanies.length
                    : 0;
                }
                const entity_data = this.competitors_list.find(
                  (competitor) => this.entityids![0] === competitor!.entityid
                );

                if (entity_data) {
                  this.competitors_list_loading_failed = false;

                  this.company_data = entity_data;
                } else {
                  // console.log('No matching competitor found for entityid:', this.entityids);
                  this.company_data = undefined;
                  this.competitors_list_loading_failed = true;

                }
              } else {
                this.competitors_list_loading_failed = true;
              }


            })
          );
        } else {
          this.competitors_list = data;

          if (this.competitors_list.length > 0) {
            this.avg_portfolio_size_gics = this.competitors_list.reduce((acc, company) => acc + company.invention_count_120m, 0) / this.competitors_list.length;
           
            const growthValidCompanies = this.competitors_list.filter(company=>(company.invention_count_12m_prior!=0 && company.invention_count_12m!=0) )
            this.avg_recent_growth = growthValidCompanies.reduce((acc, company) => acc + company.relative_growth_12m, 0) / growthValidCompanies.length;

            const qualityValidCompanies = this.competitors_list.filter(company => company.quality_average_12m !== 0);
            this.avg_innovation_quality = qualityValidCompanies.length > 0
              ? qualityValidCompanies.reduce((acc, company) => acc + company.quality_average_12m, 0) / qualityValidCompanies.length
              : 0;
          }
          const entity_data = this.competitors_list.find(
            (competitor) => this.entityids![0] === competitor!.entityid
          );

          if (entity_data) {
            this.competitors_list_loading_failed = false;

            this.company_data = entity_data;
          } else {
            // console.log('No matching competitor found for entityid:', this.entityids);
            this.company_data = undefined;
            this.competitors_list_loading_failed = true;
          }
          return of();
        }
      })
    ).subscribe({
      next: () => {
        // console.log('company_detailed_technology_benchmark processing complete.');
      },
      error: (err) => {
        // console.error('Error fetching company_detailed_technology_benchmark detailed:', err);
        this.competitors_list_loading_failed = true;
      }
    });
  }


  // Calculate the averages




  value = 1;
  getCompetitivenessInfo(value: number): { color: string, text: string } {
    switch (value) {
      case 5:
        return { color: '#85A010', text: 'Strong Innovative Competitiveness' };
      case 4:
        return { color: '#A2C614', text: 'Above Average Innovative Competitiveness' };
      case 3:
        return { color: '#FFC000', text: 'Average Innovative Competitiveness' };
      case 2:
        return { color: '#F2850E', text: 'Below Average Innovative Competitiveness' };
      case 1:
        return { color: '#C00000', text: 'Weak Innovative Competitiveness' };
      default:
        return { color: '#000000', text: 'Unknown Competitiveness' };
    }
  }


}
