import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { take, switchMap, tap, of } from 'rxjs';
import { IinnovationQuality, IPeerGroupCompetitors, ITopCompanies } from '../../../../../../core/models/results.model';
import { loadSidenavData, submitSearch } from '../../../../../../state/action';
import { AppState } from '../../../../../../state/app.state';
import { selectSidenavDataByTabIdAndKey } from '../../../../../../state/selectors';
import { GeneralInnovationActivityResultsService } from '../../../../services/general-innovation-activity-results.service';
import { CommonModule } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';
import { MaterialModule } from '../../../../../../../Material-Module';
import { MatSlideToggle, MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MainNavbarComponent } from '../../../../../../core/components/main-navbar/main-navbar.component';
import { REGION_CODES } from '../../../../../../../../public/country-region-codes';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatOptionModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import * as XLSX from 'xlsx';
import { NullableNumberPipe
 } from '../../../../../../shared/pipes/nullable-number.pipe';


@Component({
  selector: 'app-peer-group',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, NgxPaginationModule, ReactiveFormsModule, FormsModule, MatFormFieldModule, MatSelectModule, MatSlideToggleModule, MainNavbarComponent, MatTooltipModule, MatOptionModule, MatCheckboxModule, MatAutocompleteModule, MatInputModule, MatIconModule,NullableNumberPipe],
  templateUrl: './peer-group.component.html',
  styleUrl: './peer-group.component.css'
})
export class PeerGroupComponent implements OnInit, OnChanges {
  @Input() tabId!: string;
  @Input() query: string | null = null;
  @Input() gsubind: string[] | null = null;
  @Input() entityids: string[] | null = null;
  @Input() tab_name: string | null = null;

  showResults: boolean = false;
  competitors_list_loading_failed: boolean = false;
  competitors_list: IPeerGroupCompetitors[] = [];
  paginator_1_page: number = 1;
  filteredCompanyList: IPeerGroupCompetitors[] = [];
  showOnlyFavorites: boolean = false;
  searchQuery: string = '';
  countriesControl = new FormControl<string[]>([]);
  expandedRegions: Set<string> = new Set();

  countryFilterOptions = REGION_CODES;

  constructor(
    private store: Store<AppState>,
    private apiService: GeneralInnovationActivityResultsService
  ) { }

  developedMarketRegion = REGION_CODES.find(region => region.region === "Developed Markets");
  developedMarketCountries = this.developedMarketRegion
    ? this.developedMarketRegion.countries.map(country => country.code)
    : [];

  roles: string[] = [];
  ngOnInit(): void {
    this.roles = JSON.parse(localStorage.getItem('roles')!);

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['tabId']) {
      this.resetState();
      if (this.gsubind && this.gsubind[0] != null) {
        this.getCompetitors();
      }
    }
  }

  resetState(): void {
    this.showResults = false;
    this.competitors_list = [];
    this.competitors_list_loading_failed = false;
  }
  
  getOrdinalSuffix(rank: number): string {
    if (rank % 10 === 1 && rank % 100 !== 11) return 'st';
    if (rank % 10 === 2 && rank % 100 !== 12) return 'nd';
    if (rank % 10 === 3 && rank % 100 !== 13) return 'rd';
    return 'th';
  }
  
  entityid: string = '';

  getCompetitors(): void {
   // this.countriesControl.setValue(this.developedMarketCountries);
    this.entityid = this.entityids![0];
    this.showOnlyFavorites = false;
    this.showResults = true;
    this.store
      .select(selectSidenavDataByTabIdAndKey(this.tabId, 'company_detailed_technology_benchmark'))
      .pipe(
        take(1),
        switchMap((data) => {
          if (!data) {
            return this.apiService.getCompetitiveBenchmarkingPeerGroup(this.entityids, this.gsubind).pipe(
              tap((res) => {
                if (res.payload != null) {
                  this.store.dispatch(
                    loadSidenavData({
                      tabId: this.tabId,
                      sidenavKey: 'company_detailed_technology_benchmark',
                      data: res.payload,
                    })
                  );
                  this.processCompetitorData(res.payload);
                } else {
                  this.competitors_list_loading_failed = true;
                }
              })
            );
          } else {
            this.competitors_list_loading_failed = false;
            this.processCompetitorData(data);
            return of();
          }
        })
      )
      .subscribe({
        // next: () =>console.log ('Competitor data processed.'),
        error: () => (this.competitors_list_loading_failed = true),
      });
  }

  processCompetitorData(data: IPeerGroupCompetitors[]): void {
    this.competitors_list = data.map((company) => ({
      ...company,
      favorite: company.favorite ?? false,
    }));


    this.filteredCompanyList = [...this.competitors_list];
    this.applyFilters();
  }

  toggleFavorite(company: IPeerGroupCompetitors): void {
    const updatedCompany = { ...company, favorite: !company.favorite };
    const index = this.competitors_list.indexOf(company);
    if (index !== -1) {
      this.competitors_list[index] = updatedCompany;
    }
    this.applyFilters();
  }

  toggleExpand(region: string): void {
    if (this.expandedRegions.has(region)) {
      this.expandedRegions.delete(region);
    } else {
      this.expandedRegions.add(region);
    }
  }

  isExpanded(region: string): boolean {
    return this.expandedRegions.has(region);
  }

  toggleRegionSelection(region: any): void {
    const allSelected = this.isRegionFullySelected(region);
    const regionCountryCodes = region.countries.map((c: any) => c.code);

    if (allSelected) {
      const updatedSelection = (this.countriesControl.value ?? []).filter(
        (value: string) => !regionCountryCodes.includes(value)
      );
      this.countriesControl.setValue(updatedSelection);
    } else {
      const updatedSelection = Array.from(
        new Set([...(this.countriesControl.value ?? []), ...regionCountryCodes])
      );
      this.countriesControl.setValue(updatedSelection);
    }
    this.applyFilters();
  }

  toggleCountrySelection(countryCode: string, region: any): void {
    const currentSelection = this.countriesControl.value ?? [];
    const isSelected = currentSelection.includes(countryCode);

    if (isSelected) {
      this.countriesControl.setValue(
        currentSelection.filter((value: string) => value !== countryCode)
      );
    } else {
      this.countriesControl.setValue([...currentSelection, countryCode]);
    }
    this.applyFilters();
  }

  isRegionFullySelected(region: any): boolean {
    const regionCountryCodes = region.countries.map((c: any) => c.code);
    const selectedCodes = this.countriesControl.value ?? [];
    return regionCountryCodes.every((code: string) => selectedCodes.includes(code));
  }
  filterFavorites(): void {
    this.showOnlyFavorites = !this.showOnlyFavorites;
    this.applyFilters();
  }


  //log(size+1) log/log_max
  normalizeInnovationScore(companies: IPeerGroupCompetitors[]): void {

    companies.forEach(company => {
      company.normalized_size = Math.log((company.invention_count_120m == null ? 0 : company.invention_count_120m) + 1);
    });

    const maxLogSize = Math.max(...companies.map(c => c.normalized_size));

    companies.forEach(company => {
      company.normalized_size = company.normalized_size / maxLogSize;
    });


    companies.forEach(company => {
      company.normalized_relative = Math.log((company.relative_growth_12m == null ? -100 : company.relative_growth_12m )+ 100 + 1);//100 for percentage offset(0 to infinity) and 1 for log
    });

    const maxLogRelativeGrowth = Math.max(...companies.map(c => c.normalized_relative));

    companies.forEach(company => {
      company.normalized_relative = company.normalized_relative / maxLogRelativeGrowth;
    });


    companies.forEach(company => {
      const quality = company.quality_average_12m;
      const adjustedQuality = (quality === null) ? 0.1 : quality; // Default to 0.1 if null/NA/empty
      company.innovation_score_raw = company.normalized_size * company.normalized_relative * adjustedQuality;
    });

    

    const minScore = 0;
    const maxScore = Math.max(...companies.map(c => c.innovation_score_raw));

    companies.forEach(company => {
      company.innovation_score =
        minScore === maxScore
          ? 100
          : ((company.innovation_score_raw - minScore) / (maxScore - minScore)) * 100;
    });
  }


  rankInnovationScore(companies: IPeerGroupCompetitors[]) {
    companies.sort((a, b) => b.innovation_score - a.innovation_score);
    companies.forEach((company, index) => {
      company.rank = index + 1;
    });
  }

  applyFilters(): void {
    this.paginator_1_page = 1;
    this.filteredCompanyList = this.competitors_list;
    this.normalizeInnovationScore(this.filteredCompanyList);
    this.rankInnovationScore(this.filteredCompanyList);

    if (this.countriesControl.value?.length) {
      this.filteredCompanyList = this.filteredCompanyList.filter((company) =>
        this.countriesControl.value!.includes(company.country)
      );
      this.normalizeInnovationScore(this.filteredCompanyList);
      this.rankInnovationScore(this.filteredCompanyList);
    }
    if (this.showOnlyFavorites) {
      this.filteredCompanyList = this.filteredCompanyList.filter((company) => company.favorite);
      this.paginator_1_page = 1;
    }

    if (this.searchQuery) {
      this.filteredCompanyList = this.filteredCompanyList.filter((company) =>
        company.entity_name.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    }

  }


  newSearch(company: IPeerGroupCompetitors) {
    const searchParams = { 'companies': [company] };

    this.store.dispatch(submitSearch({ searchParams, searchType: 'company' }));
  }

  getBarColor(score: number): string {
    if (score >= 90) return 'green';
    if (score >= 50) return 'orange';
    return 'red';
  }


  // Method to download the Excel file
  downloadExcel(): void {
    const filteredList = this.competitors_list.map(item => ({
         rank:item.rank,
         company:item.entity_name,
         isin:item.isin,
         country:item.country,
         company_type:item.companytype,
         size:item.invention_count_120m.toFixed(0),
         absolute_growth:item.invention_count_12m.toFixed(0),
         relative_growth:item.relative_growth_12m.toFixed(1),
         quality_score:item.quality_average_12m.toFixed(1),
         innovation_score: item.innovation_score.toFixed(1),
       }));
     
       const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(filteredList);
     
       const wb: XLSX.WorkBook = XLSX.utils.book_new();
       const sheetName = "competitors";
       XLSX.utils.book_append_sheet(wb, ws, sheetName);
     
       const fileName = this.tab_name ? `${this.tab_name}.xlsx` : 'excel_file.xlsx';
       XLSX.writeFile(wb, fileName);
  }
}